import './Login.scss';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';

function Login() {
  const { register, handleSubmit } = useForm({ shouldUseNativeValidation: true });
  const onSubmit = async (data: any) => {
    // console.log(data);
  };

  return (
    <div className="login">
      <div className="content">
        <div className="logo">
          <img src={`${process.env.PUBLIC_URL}/img/moneta-by-dchf-logo.svg`}
            className="App-logo" alt="logo" />
        </div>

        <h1 className='font-bold'>Win Moneta Tokens</h1>
        <p className='font-regular'>And be among the first to use DCHF Platform</p>

        <div className="card">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="f-group">
              <label htmlFor="email" className='font-medium upcase'>
                E-mail address
              </label>
              <input type="email" placeholder="Enter your e-mail address" id="email"
                {...register("email", { required: "Please enter your e-mail address." })} // custom message
              />
            </div>

            <div className="line"></div>

            <button type="submit" className='font-book'>Subscribe</button>

            <Link to={'/terms-and-conditions'} className='font-regular'>Terms and Conditions</Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
