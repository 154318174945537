import {Route, Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import TermsAndConditions from './pages/terms-and-conditions/Terms_and_conditions';

const MainRouter = () => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="*" element={<Login />} />
      {/* // <Route path="/users" component={Users}/>
      // <Route path="/signup" component={Signup}/>
      // <Route path="/signin" component={Signin}/>
      // <PrivateRoute path="/user/edit/:userId" component={EditProfile}/>
      // <Route path="/user/:userId" component={Profile}/>
      // <PrivateRoute path="/expenses/all" component={Expenses}/>
      // <PrivateRoute path="/expenses/new" component={NewExpense}/>
      // <PrivateRoute path="/expenses/reports" component={Reports}/> */}
    </Routes>
  );
}

export default MainRouter
