import { Link } from "react-router-dom";
import "./Terms_and_conditions.scss";

function TermsAndConditions() {
  return (
    <div className="term-and-conditions">
      <div className="header">
        <Link to="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="36"
            width="36"
            viewBox="0 0 48 48"
          >
            <path d="M20 44 0 24 20 4l2.8 2.85L5.65 24 22.8 41.15Z" />
          </svg>
        </Link>
        <div className="logo">
          <img
            src={`${process.env.PUBLIC_URL}/img/moneta-by-dchf-logo.svg`}
            className="App-logo"
            alt="logo"
          />
        </div>
        <span></span>
      </div>
      <div className="card">
        <div className="body">
          <h1>
            <strong>1. Privacy Policy</strong>
          </h1>
          <p>
            The website of Moneta (hereinafter referred to as “Moneta”) is
            subject to Swiss data protection law, in particular in accordance
            with the Swiss Federal Data Protection Act (DSG), as well as any
            applicable foreign data protection laws, such as the Basic Data
            Protection Regulation (DSGVO) of the European Union (EU). The EU
            acknowledges that Swiss data protection law guarantees adequate data
            protection.
          </p>
          <p>
            Access to our website is encrypted using transport encryption
            (SSL/TLS).
          </p>
          <p>
            We are very pleased about your interest in our company. Data
            protection has a particularly high priority for us. It is generally
            possible to use the Moneta website without providing any personal
            data. However, if a person concerned wishes to take advantage of
            special services offered by our company via our website, it may be
            necessary to process personal data. If it is necessary to process
            personal data and there is no legal basis for such processing, we
            generally obtain the consent of the person concerned.
          </p>
          <p>
            The processing of personal data, such as the name, address, e-mail
            address or telephone number of a data subject, is always carried out
            in accordance with the statutory data protection regulations. By
            means of this data protection declaration, our company wishes to
            inform the public about the type, scope and purpose of the personal
            data collected, used and processed by us. Furthermore, this data
            protection declaration informs affected persons about their rights.
          </p>
          <p>
            Moneta, as the person responsible for processing, has implemented
            numerous technical and organisational measures to ensure that the
            personal data processed via this website is protected as completely
            as possible. Nevertheless, Internet-based data transmissions can
            generally have security gaps, so that absolute protection cannot be
            guaranteed. For this reason, every person concerned is free to
            transmit personal data to us by alternative means, such as by
            telephone
          </p>
          <h1>
            <strong>2. Definitions</strong>
          </h1>
          <p>
            The Moneta data protection declaration is based on the terms used by
            the European legislator for directives and regulations when the
            basic data protection regulation (DSGVO) was issued. Our data
            protection declaration should be easy to read and understand for the
            public as well as for our customers and business partners. To ensure
            this, we would like to explain the terms used in advance.
          </p>
          <p>
            We use the following terms in this data protection declaration,
            among others:
          </p>
          <p>
            <strong>(a) Personal data</strong>
            <br />
            Personal data means any information relating to an identified or
            identifiable natural person (hereinafter referred to as “data
            subject”). An identifiable natural person is one who can be
            identified, directly or indirectly, in particular by reference to an
            identifier such as a name, an identification number, location data,
            on-line identification, or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity of that natural person.
          </p>
          <p>
            <strong>(b) Data subject</strong>
            <br />
            Data subject means any identified or identifiable natural person
            whose personal data are processed by the controller.
          </p>
          <p>
            <strong>(c) processing</strong>
            <br />
            Processing is any operation or set of operations, performed with or
            without the aid of automated means, which is performed upon personal
            data, such as collection, recording, organisation, organisation,
            filing, storage, adaptation or alteration, retrieval, consultation,
            use, disclosure by transmission, dissemination or otherwise making
            available, alignment or combination, restriction, erasure or
            destruction.
          </p>
          <p>
            <strong>(d) Restriction of processing</strong>
            <br />
            Restriction of processing is the marking of stored personal data
            with the aim of limiting their future processing.
          </p>
          <p>
            <strong>(e) profiling</strong>
            <br />
            Profiling is any automated processing of personal data consisting of
            the use of such personal data to evaluate certain personal aspects
            relating to a natural person, in particular to analyse or predict
            aspects relating to the performance of work, economic situation,
            health, personal preferences, interests, reliability, conduct,
            location or change of location of that natural person.
          </p>
          <p>
            <strong>(f) Pseudonymisation</strong>
            <br />
            Pseudonymisation is the processing of personal data in such a way
            that the personal data can no longer be attributed to a specific
            data subject without additional information, provided that this
            additional information is kept separately and is subject to
            technical and organisational measures which ensure that the personal
            data is not attributed to an identified or identifiable natural
            person.
          </p>
          <p>
            <strong>(g) Controller or controller</strong>
            <br />
            Controller or data controller is the natural or legal person, public
            authority, agency or any other body which alone or jointly with
            others determines the purposes and means of the processing of
            personal data.
          </p>
          <p>
            <strong>(h) processor</strong>
            <br />
            Processor is a natural or legal person, public authority, agency or
            other body which processes personal data on behalf of the
            controller.
          </p>
          <p>
            <strong>(i) recipient</strong>
            <br />
            The recipient is any natural or legal person, public authority,
            agency or other body to whom personal data are disclosed, whether or
            not that person is a third party.
          </p>
          <p>
            <strong>(j) Third party</strong>
            <br />A third party is a natural or legal person, public authority,
            agency or body other than the data subject, the controller, the
            processor and the persons who, under the direct authority of the
            controller or the processor, are authorized to process the personal
            data.
          </p>
          <p>
            <strong>(k) Consent</strong>
            <br />
            Consent shall mean any freely given and informed unequivocal
            expression of the data subject’s wishes in the specific case, in the
            form of a statement or any other unequivocal affirmative act by
            which the data subject signifies his or her consent to the
            processing of personal data relating to him or her.
          </p>
          <h1>
            <strong>3. Name and address of the controller</strong>
          </h1>
          <p>
            Enquiries from supervisory authorities or data subjects to the Data
            Protection Officer are usually made by e-mail, but can also be
            submitted by letter post:
          </p>
          <p>
            Moneta
            <br />
            Almas Tower, 48 CV-78
            <br />
            Dubai, UAE
          </p>
          <h1>
            <strong>4. Information you give us</strong>
          </h1>
          <p>This is information about you that you give us through:</p>
          <p>
            – Filling out forms on our website (or other forms that we ask you
            to fill out)
            <br />
            – Presenting a business card (or similar),
            <br />– Correspondence with us by telephone, mail, e-mail or
            otherwise
          </p>
          <p>
            This may include, for example, your name, address, e-mail address
            and telephone number, information about your business relationship
            with us and information about your professional role, background and
            interests.
          </p>
          <h1>
            <strong>5. Other Information</strong>
          </h1>
          <p>
            We may also collect some information from other sources. For
            example:
          </p>
          <p>
            – If we have a business relationship with the organization you
            represent, your colleagues or other business contacts may give us
            information about you such as your contact information or details of
            their role in the business relationship.
            <br />– Sometimes we collect information from third parties or
            publicly available sources for anti-money laundering, background
            control and similar purposes to protect our business and to comply
            with our legal and regulatory obligations.
          </p>
          <h1>
            <strong>6. Cookies</strong>
          </h1>
          <p>
            The Moneta website uses cookies and web beacons. Cookies are text
            files that are stored on a computer system via an Internet browser.
          </p>
          <p>
            Numerous websites and servers use cookies. Many cookies contain a
            so-called cookie ID. A cookie ID is a unique identifier of the
            cookie. It consists of a string of characters that can be used to
            assign Internet pages and servers to the specific Internet browser
            in which the cookie was stored. This enables the Internet pages and
            servers visited to distinguish the individual browser of the person
            concerned from other Internet browsers that contain other cookies. A
            specific Internet browser can be recognized and identified by means
            of the unique cookie ID.
          </p>
          <p>
            By using cookies and counting pixels, we can provide users of this
            website with more user-friendly services that would not be possible
            without the setting of cookies.
          </p>
          <p>
            By means of a cookie and counting pixels, the information and offers
            on our website can be optimised in the interest of the user. As
            already mentioned, cookies enable us to recognize the users of our
            website. The purpose of this recognition is to make it easier for
            users to use our website. The user of a website that uses cookies,
            for example, does not have to enter his or her access data again
            each time he or she visits the website, as this is done by the
            website and the cookie stored on the user’s computer system.
          </p>
          <p>
            The person concerned can prevent the setting of cookies by our
            website at any time by means of an appropriate setting in the
            Internet browser used, thereby permanently opposing the setting of
            cookies. Furthermore, cookies that have already been set can be
            deleted at any time via an Internet browser or other software
            programs. Counting pixels can be blocked at any time in the Internet
            browser settings or with appropriate browser extensions. This is
            possible in all common Internet browsers. If the person concerned
            deactivates the setting of cookies and blocks tracking pixels, not
            all functions of our website may be fully usable.
          </p>
          <h1>
            <strong>7. Collection of general data and information</strong>
          </h1>
          <p>
            The Moneta website collects a range of general data and information
            every time a data subject or automated system calls up the website.
            These general data and information are stored in the server log
            files. The following can be recorded: (1) browser types and versions
            used, (2) the operating system used by the accessing system, (3) the
            website from which an accessing system accesses our website
            (so-called referrer), (4) the sub-websites that are accessed via an
            accessing system on our website, (5) the date and time of an access
            to the Internet site, (6) an Internet Protocol address (IP address),
            (7) the Internet service provider of the accessing system and (8)
            other similar data and information which serve to avert danger in
            the event of attacks on our information technology systems.
          </p>
          <p>
            When using this general data and information, Moneta will not draw
            any conclusions about the person concerned. Rather, this information
            is required in order to (1) deliver the contents of our website
            correctly, (2) optimise the contents of our website as well as the
            advertising for it, (3) ensure the permanent operability of our
            information technology systems and the technology of our website,
            and (4) provide law enforcement authorities with the information
            necessary for criminal prosecution in the event of a cyber attack.
            This anonymously collected data and information is therefore
            statistically evaluated by Moneta on the one hand and also with the
            aim of increasing data protection and data security in our company,
            in order to ultimately ensure an optimal level of protection for the
            personal data processed by us. The anonymous data of the server log
            files are stored separately from all personal data provided by a
            person concerned.
          </p>
          <h1>
            <strong>8. Registration on our website</strong>
          </h1>
          <p>
            The data subject has the possibility to register on the website of
            the controller by providing personal data. The personal data
            transmitted to the controller will be determined by the input mask
            used for registration. The personal data entered by the data subject
            are collected and stored exclusively for internal use by the data
            controller and for the data subject’s own purposes. The controller
            may arrange for the personal data to be transferred to one or more
            processors, such as a parcel service provider, which will also use
            the personal data exclusively for an internal use attributable to
            the controller.
          </p>
          <p>
            Furthermore, by registering on the controller’s website, the IP
            address assigned to the data subject by the Internet Service
            Provider (ISP), the date and time of registration are stored. The
            storage of this data is carried out against the background that this
            is the only way to prevent the misuse of our services and, if
            necessary, to enable the clarification of criminal offences
            committed. In this respect, the storage of this data is necessary to
            protect the person responsible for processing. As a matter of
            principle, this data will not be passed on to third parties unless
            there is a legal obligation to do so or the passing on of the data
            serves criminal prosecution.
          </p>
          <p>
            The registration of the data subject under voluntary disclosure of
            personal data serves the purpose of the data controller to offer the
            data subject content or services which, due to the nature of the
            matter, can only be offered to registered users. Registered persons
            are free to modify the personal data provided during registration at
            any time or to have them completely deleted from the data stock of
            the data controller.
          </p>
          <p>
            The data controller shall at any time upon request provide any data
            subject with information as to which personal data relating to the
            data subject are stored. Furthermore, the controller shall correct
            or delete personal data at the request or notification of the data
            subject, unless this is contrary to any legal obligation to retain
            data. The entire staff of the data controller is available to the
            data subject as contact persons in this context.
          </p>
          <h1>
            <strong>9. Subscription to our newsletter</strong>
          </h1>
          <p>
            On the Moneta website, users are given the opportunity to subscribe
            to various newsletters from our company. Which personal data is
            transmitted to the data controller when ordering the newsletters can
            be seen from the input mask used for this purpose.
          </p>
          <p>
            Moneta informs its customers, business partners and representatives
            of interests at regular intervals by means of its newsletters about
            company offers and other operational aspects. In principle, a
            newsletter from our company can only be received by the person
            concerned if (1) the person concerned has a valid e-mail address and
            (2) the person concerned registers to receive the newsletter. For
            legal reasons, a confirmation e-mail will be sent to the e-mail
            address first registered by a data subject for the newsletter
            mailing, using the double opt-in procedure. This confirmation e-mail
            is used to check whether the owner of the e-mail address, as the
            data subject, has authorized the receipt of the newsletter.
          </p>
          <p>
            When registering for a newsletter, we also save the IP address
            assigned by the Internet Service Provider (ISP) of the computer
            system used by the person concerned at the time of registration as
            well as the date and time of registration. The collection of this
            data is necessary in order to be able to trace the (possible) misuse
            of a data subject’s e-mail address at a later date and therefore
            serves to provide legal protection for the data controller.
          </p>
          <p>
            The personal data collected during registration for the newsletter
            is used exclusively for sending our newsletter. In addition,
            subscribers to the newsletter could be informed by e-mail if this is
            necessary for the operation of the newsletter service or for
            registration, as could be the case if there are changes to the
            newsletter offer or if technical conditions change. The personal
            data collected within the scope of the newsletter service will not
            be passed on to third parties.
          </p>
          <p>
            The subscription to our newsletter can be cancelled by the person
            concerned at any time. The consent to the storage of personal data,
            which the person concerned has given us for the newsletter service,
            can be revoked at any time. For the purpose of revoking this
            consent, a corresponding link can be found in every newsletter. It
            is also possible to unsubscribe from the newsletter at any time
            directly on the website of the data controller or to inform the data
            controller in another way.
          </p>
          <h1>
            <strong>10. Newsletter Tracking</strong>
          </h1>
          <p>
            The newsletters of Moneta contain so-called counting pixels. A
            pixel-code is a thumbnail graphic embedded in e-mails sent in HTML
            format to enable log file recording and analysis. This enables a
            statistical evaluation of the success or failure of online marketing
            campaigns. By means of the embedded pixel-code, Moneta can identify
            whether and when an e-mail was opened by a person concerned and
            which links contained in the e-mail were accessed by the person
            concerned.
          </p>
          <p>
            Such personal data collected via the embedded tracking pixels in the
            newsletters are stored and evaluated by the data controller in order
            to optimise the newsletter dispatch and to adapt the content of
            future newsletters even better to the interests of the data subject.
            This personal data is not passed on to third parties. Data subjects
            are entitled at any time to revoke the separate declaration of
            consent given in this regard via the double opt-in procedure. After
            revocation, these personal data will be deleted by the data
            controller. Unsubscribing from receiving the newsletter is
            automatically interpreted by Moneta as a revocation.
          </p>
          <h1>
            <strong>11. Contact option via the website</strong>
          </h1>
          <p>
            Due to legal requirements, the Moneta website contains information
            that enables rapid electronic contact with our company and direct
            communication with us, which also includes a general address for
            so-called electronic mail (e-mail address). If a data subject
            contacts the data controller by e-mail or via a contact form, the
            personal data transmitted by the data subject is automatically
            stored. For security reasons, the IP address is also recorded. Such
            personal data transmitted by a data subject to the controller on a
            voluntary basis are stored for the purposes of processing or
            contacting the data subject. This personal data is not passed on to
            third parties.
          </p>
          <h1>
            <strong>12. Use of Google Analytics</strong>
          </h1>
          <p>
            We use Google Analytics from Google Inc, 1600 Amphitheatre Parkway,
            Mountain View, CA 94.043 USA. This is a tool that can be used to
            analyse the use of web pages. Google uses various techniques for
            this purpose, including storing cookies on your computer. These
            store information about the use of our site, which we use to improve
            our offer.
          </p>
          <p>
            Google Analytics uses cookies and usually stores them outside the
            EU/EFTA area. Google uses this information to evaluate the use of
            the website for Moneta and to compile reports on website activities
            and Internet usage. Furthermore, Google transfers this information
            to third parties according to its own information, provided this is
            legally required or if third parties process this data on behalf of
            Google. The IP address transmitted by the browser within the scope
            of Google Analytics is not merged with other Google data. Users can
            prevent the storage of cookies (see “Cookies” above). Furthermore,
            users can prevent the transmission of data generated by the cookie
            and related to their use of the website (including IP address) to
            Google and the processing of this data by Google by downloading the
            browser plug-in available under the following link and installing
            https://tools.google.com/dlpage/gaoptout?hl=de
          </p>
          <h1>
            <strong>16. Use of Google Maps</strong>
          </h1>
          <p>
            This website uses Google Maps API to display geographical
            information visually and to calculate travel times with the travel
            planner. When using Google Maps, Google also collects, processes and
            uses data on the use of the map functions by visitors. Further
            information about data processing by Google can be found in the
            Google data protection information. There you can also change your
            personal data protection settings in the data protection centre.
          </p>
          <p>
            Detailed instructions on how to manage your own data in connection
            with Google products can be found here.
          </p>
          <h1>
            <strong>21. Use of Social PlugIns Facebook</strong>
          </h1>
          <p>
            Our website uses so-called social plugins (“plugins”) of the social
            network Facebook, which is operated by Facebook Inc, 1601 S.
            California Ave, Palo Alto, CA 94304, USA (“Facebook”). The plugins
            are marked with a Facebook logo or the addition “Social Plug-in from
            Facebook” or “Facebook Social Plugin”. An overview of the Facebook
            plugins and their appearance can be found here:
            https://developers.facebook.com/docs/plugins
          </p>
          <p>
            When you call up a page of our website that contains such a plugin,
            your browser establishes a direct connection to the Facebook
            servers. The content of the plugin is transmitted by Facebook
            directly to your browser and integrated into the page. Through this
            integration, Facebook receives the information that your browser has
            called up the corresponding page of our website, even if you do not
            have a Facebook profile or are not currently logged in to Facebook.
            This information (including your IP address) is transmitted by your
            browser directly to a Facebook server in the USA and stored there.
          </p>
          <p>
            If you are logged in to Facebook, Facebook can directly assign your
            visit to our website to your Facebook profile. If you interact with
            the plugins, for example by clicking the “Like” button or submitting
            a comment, this information is also transmitted directly to a
            Facebook server and stored there. The information is also published
            on your Facebook profile and displayed to your Facebook friends.
          </p>
          <p>
            For the purpose and scope of data collection and the further
            processing and use of data by Facebook, as well as your rights and
            settings options for protecting your privacy, please refer to the
            Facebook data protection information:
            http://www.facebook.com/policy.php
          </p>
          <p>
            If you do not want Facebook to assign the data collected via our
            website directly to your Facebook profile, you must log out of
            Facebook before visiting our website. You can also completely
            prevent the loading of the Facebook plugins with add-ons for your
            browser, e.g.
          </p>
          <p>
            for Mozilla Firefox:
            <br />
            https://addons.mozilla.org/de/firefox/addon/facebook_blocker/?src=search
          </p>
          <p>
            for Opera:
            <br />
            https://addons.opera.com/de/extensions/details/facebook-blocker/?display=en
          </p>
          <p>
            for Chrome:
            <br />
            https://chrome.google.com/webstore/detail/block-facebook/gebclbfnlcebcljmgblacllmjkfidoef
          </p>
          <p>
            <strong>(b) Twitter</strong>
            <br />
            On our website, so-called social plugins (“Plugins”) of the
            microblogging service Twitter are used, which is operated by Twitter
            Inc, 1355 Market St, Suite 900, San Francisco, CA 94103, USA
            (“Twitter”). The plugins are marked with a Twitter logo, for example
            in the form of a blue “Twitter bird”. An overview of the Twitter
            plugins and their appearance can be found here:
            https://about.twitter.com/en_us/company/brand-resources.html
          </p>
          <p>
            If you call up a page of our website that contains such a plugin,
            your browser will establish a direct connection to the servers of
            Twitter. The content of the plugin is transmitted by Twitter
            directly to your browser and integrated into the page. Through the
            integration, Twitter receives the information that your browser has
            called up the corresponding page of our website, even if you do not
            have a Twitter profile or are not currently logged in to Twitter.
            This information (including your IP address) is transmitted by your
            browser directly to a Twitter server in the USA and stored there.
          </p>
          <p>
            If you are logged in to Twitter, Twitter can directly assign your
            visit to our website to your Twitter account. If you interact with
            the plugins, for example by clicking the “Twitter” button, the
            corresponding information is also transmitted directly to a Twitter
            server and stored there. The information is also published on your
            Twitter account and displayed to your contacts.
          </p>
          <p>
            For the purpose and scope of data collection and the further
            processing and use of the data by Twitter as well as your rights and
            settings options to protect your privacy, please refer to the
            Twitter data protection information: https://twitter.com/privacy
          </p>
          <p>
            If you do not want Twitter to associate the data collected via our
            website directly with your Twitter account, you must log out of
            Twitter before visiting our website. You can also completely prevent
            the loading of the Twitter plugins with add-ons for your browser,
            e.g. with the script blocker “NoScript” (http://noscript.net/).
          </p>
          <p>
            <strong>(c) Instagram</strong>
            <br />
            Our website uses social plugins (“Plugins”) from Instagram, which is
            operated by Instagram LLC, 1601 Willow Road, Menlo Park, CA 94025,
            USA (“Instagram”). The plugins are marked with an Instagram logo,
            for example in the form of an “Instagram Camera”. An overview of the
            Instagram plugins and their appearance can be found here:
            http://blog.instagram.com/post/36222022872/introducing-instagram-badges
          </p>
          <p>
            When you access a page on our site that contains such a plugin, your
            browser will establish a direct connection to Instagram’s servers.
            The content of the plugin is transmitted by Instagram directly to
            your browser and integrated into the page. This integration informs
            Instagram that your browser has called up the corresponding page on
            our website, even if you do not have an Instagram profile or are not
            currently logged in to Instagram. This information (including your
            IP address) is transmitted by your browser directly to an Instagram
            server in the USA and stored there.
          </p>
          <p>
            If you are logged in to Instagram, Instagram can directly associate
            your visit to our website with your Instagram account. When you
            interact with the plug-ins, for example by clicking the “Instagram”
            button, this information is also transmitted directly to an
            Instagram server and stored there. The information is also published
            to your Instagram account and displayed to your contacts.
          </p>
          <p>
            For the purpose and scope of data collection and further processing
            and use of the data by Instagram, as well as your rights and privacy
            preferences, please refer to Instagram’s Privacy Policy:
            https://help.instagram.com/155833707900388/
          </p>
          <p>
            If you do not want Instagram to associate the information collected
            through our site directly with your Instagram account, you must log
            out of Instagram before visiting our site. You can also completely
            prevent Instagram plugins from loading by using add-ons for your
            browser, such as the script blocker “NoScript”
            (http://noscript.net/).
          </p>
          <p>
            <strong>(d) YouTube</strong>
            <br />
            Our website uses social plugins (“Plugins”) from YouTube, which is
            operated by YouTube, LLC, 901 Cherry Ave, San Bruno, CA 94066, USA
            (“YouTube”). YouTube, LLC is a subsidiary of Google Inc, 1600
            Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA. The plugins
            are marked with a YouTube logo, for example in the form of a
            “YouTube button”.
          </p>
          <p>
            Each time the data subject accesses one of the individual pages of
            this website operated by the data controller and on which a YouTube
            component (YouTube video) has been integrated, the Internet browser
            on the data subject’s information technology system is automatically
            prompted by the respective YouTube component to download a display
            of the corresponding YouTube component from YouTube. Further
            information on YouTube can be found at
            https://www.youtube.com/yt/about/de/. As part of this technical
            process, YouTube and Google are informed which specific subpage of
            our website is visited by the data subject.
          </p>
          <p>
            If the person concerned is logged on to YouTube at the same time,
            YouTube recognizes which specific subpage of our website the person
            concerned is visiting by calling up a subpage containing a YouTube
            video. This information is collected by YouTube and Google and
            assigned to the respective YouTube account of the person concerned.
          </p>
          <p>
            YouTube and Google receive information via the YouTube component
            that the data subject has visited our website whenever the data
            subject is logged on to YouTube at the same time as he or she visits
            our website, regardless of whether the data subject clicks on a
            YouTube video or not. If the data subject does not wish this
            information to be transmitted to YouTube and Google, he or she can
            prevent the transmission by logging out of his or her YouTube
            account before accessing our website.
          </p>
          <p>
            The privacy policy published by YouTube, which is available at
            https://www.google.de/intl/de/policies/privacy/, provides
            information about the collection, processing and use of personal
            data by YouTube and Google.
          </p>
          <h1>
            <strong>22. Routine deletion and blocking of personal data</strong>
          </h1>
          <p>
            The controller shall process and store personal data relating to the
            data subject only for the period of time necessary to achieve the
            purpose of storage or where provided for by the European legislator
            or other legislator in laws or regulations to which the controller
            is subject.
          </p>
          <p>
            If the purpose of storage ceases to apply or if a storage period
            prescribed by the European Directives and Regulations Giver or any
            other competent legislator expires, the personal data will be
            blocked or deleted as a matter of routine and in accordance with the
            statutory provisions.
          </p>
          <h1>
            <strong>23. Rights of the data subject</strong>
          </h1>
          <p>
            If you wish to exercise any of the following rights, please contact
            us as set out in paragraph 3.
          </p>
          <p>
            You may also submit a complaint about our processing of your
            personal data to the Federal Data Protection and Information
            Commissioner (FDPIC, www.edoeb.admin.ch).
          </p>
          <p>
            <strong>(a) Right to confirmation</strong>
            <br />
            Every data subject has the right, granted by the Swiss legislator or
            by the European legislator for directives and regulations, to obtain
            confirmation from the controller as to whether personal data
            relating to him or her are being processed. If a data subject wishes
            to exercise this right of confirmation, he or she may contact our
            data protection officer at any time.
          </p>
          <p>
            <strong>(b) Right to information</strong>
            <br />
            Every person affected by the processing of personal data has the
            right, granted by the Swiss legislator or by the European Directive
            and Regulation, to obtain at any time and free of charge from the
            data controller information about the personal data stored about
            him/her and a copy of this information. Furthermore, the European
            legislator has granted the data subject access to the following
            information:
          </p>
          <p>
            – the purposes of processing
            <br />
            – the categories of personal data processed
            <br />
            – the recipients or categories of recipients to whom the personal
            data have been or will be disclosed, in particular to recipients in
            third countries or international organisations
            <br />
            – if possible, the planned duration for which the personal data will
            be stored or, if this is not possible, the criteria for determining
            this duration
            <br />
            – the existence of a right of rectification or erasure of personal
            data relating to him or her or of a restriction on processing by the
            controller or a right to object to such processing
            <br />
            – the existence of a right of appeal to a supervisory authority
            <br />
            – if the personal data are not collected from the data subject: All
            available information on the origin of the data
            <br />
            – the existence of an automated decision-making process including
            profiling in accordance with Article 22 Paragraphs 1 and 4 DPA and –
            at least in these cases – meaningful information on the logic
            involved and the scope and intended consequences of such processing
            for the data subject
            <br />– The data subject shall also have the right to obtain
            information as to whether personal data have been transferred to a
            third country or to an international organisation. If this is the
            case, the data subject shall also have the right to obtain
            information on the appropriate safeguards relating to the transfer.
          </p>
          <p>
            If a data subject wishes to exercise this right of access, he or she
            may contact our Data Protection Officer at any time.
          </p>
          <p>
            <strong>(c) Right of rectification</strong>
            <br />
            Any person affected by the processing of personal data has the
            right, granted by the Swiss legislator or by the European legislator
            of directives and regulations, to demand the immediate correction of
            incorrect personal data concerning him/her.
          </p>
          <p>
            Furthermore, the data subject has the right to request the
            completion of incomplete personal data, including by means of a
            supplementary declaration, having regard to the purposes of the
            processing.
          </p>
          <p>
            If a data subject wishes to exercise this right of rectification, he
            or she may contact our data protection officer at any time.
          </p>
          <p>
            <strong>(d) Right of deletion (right to be forgotten)</strong>
            <br />
            Any person affected by the processing of personal data has the
            right, granted by the European Directives and Regulations, to obtain
            from the controller the immediate erasure of personal data relating
            to him/her, if one of the following reasons applies and provided
            that the processing is not necessary:
          </p>
          <p>
            – the accuracy of the personal data is contested by the data
            subject, for a period of time sufficient to enable the controller to
            verify the accuracy of the personal data
            <br />
            – The processing is unlawful, the data subject refuses to have the
            personal data deleted and instead requests that the use of the
            personal data be restricted.
            <br />
            – The controller no longer needs the personal data for the purposes
            of the processing, but the data subject needs them in order to
            assert, exercise or defend legal claims.
            <br />
            – The data subject has lodged an objection to the processing
            pursuant to Art. 21 (1) DPA and it is not yet clear whether the
            legitimate reasons of the controller outweigh those of the data
            subject.
            <br />– If one of the above-mentioned conditions is met and a data
            subject wishes to request the restriction of personal data stored at
            Moneta, he or she can contact our data protection officer at any
            time.
          </p>
          <p>
            If Moneta’s personal data has been made public and our company, as
            the responsible party, is obliged to delete the personal data in
            accordance with Art. 17 Paragraph 1 DSGVO, Moneta will take
            appropriate measures, also of a technical nature, taking into
            account the available technology and implementation costs, to inform
            other data controllers who process the published personal data that
            the data subject has requested these other data controllers to
            delete all links to this personal data or copies or replications of
            this personal data, unless the processing is necessary. Our data
            protection officer will take the necessary steps in individual
            cases.
          </p>
          <p>
            <strong>(e) Right to limit processing</strong>
            <br />
            Any person affected by the processing of personal data has the
            right, granted by the European Directives and Regulations, to
            request the controller to restrict the processing if one of the
            following conditions is met:
          </p>
          <p>
            – the accuracy of the personal data is contested by the data
            subject, for a period of time sufficient to enable the controller to
            verify the accuracy of the personal data
            <br />
            – the processing is unlawful, the data subject refuses to have the
            personal data deleted and instead requests the limitation of the use
            of the personal data
            <br />
            – The controller no longer needs the personal data for the purposes
            of the processing, but the data subject needs them in order to
            assert, exercise or defend legal claims.
            <br />
            – The data subject has lodged an objection to the processing
            pursuant to Art. 21 (1) DPA and it is not yet clear whether the
            legitimate reasons of the controller outweigh those of the data
            subject.
            <br />– If one of the above-mentioned conditions is met and a data
            subject wishes to request the restriction of personal data stored at
            Moneta, he or she can contact our data protection officer at any
            time.
          </p>
          <p>
            <strong>(f) Right to data transferability</strong>
            <br />
            Any person affected by the processing of personal data has the
            right, granted by the European Directives and Regulations, to
            receive the personal data concerning him/her which have been made
            available to a controller by the data subject in a structured,
            common and machine-readable format. He or she also has the right to
            have this data communicated to another controller without hindrance
            by the controller to whom the personal data has been made available,
            provided that the processing is based on the consent pursuant to
            Art. 6 para. 1 letter a DPA or Art. 9 para. 2 letter a DPA or on a
            contract pursuant to Art. 6 para. 1 letter b DPA and that the
            processing is carried out by means of automated procedures, unless
            the processing is necessary for the performance of a task carried
            out in the public interest or in the exercise of official authority
            vested in the controller.
          </p>
          <p>
            Furthermore, when exercising their right to data transfer pursuant
            to Art. 20 para. 1 DPA, the data subject has the right to obtain
            that personal data be transferred directly from one controller to
            another controller, insofar as this is technically feasible and
            provided that this does not adversely affect the rights and freedoms
            of other persons.
          </p>
          <p>
            To assert the right to data transfer, the person concerned can
            contact our data protection officer at any time.
          </p>
          <p>
            <strong>(g) Right to object</strong>
            <br />
            Every person concerned by the processing of personal data has the
            right, granted by the European legislator, to object at any time,
            for reasons arising from his or her particular situation, to the
            processing of personal data concerning him or her carried out
            pursuant to Article 6(1)(e) or (f) of the DPA. This also applies to
            profiling based on these provisions.
          </p>
          <p>
            In the event of an objection, Moneta will no longer process the
            personal data unless we can prove compelling reasons for processing
            that are worthy of protection and outweigh the interests, rights and
            freedoms of the person concerned, or the processing serves to
            assert, exercise or defend legal claims.
          </p>
          <p>
            If Moneta processes personal data for the purpose of direct
            advertising, the person concerned has the right to object to the
            processing of personal data for the purpose of such advertising at
            any time. This also applies to profiling, insofar as it is connected
            with such direct advertising. If the data subject objects to Moneta
            processing for the purpose of direct advertising, we will no longer
            process the personal data for these purposes.
          </p>
          <p>
            Furthermore, the data subject has the right to object, for reasons
            arising from his or her particular situation, to the processing of
            personal data concerning him or her that is carried out at Moneta
            for scientific or historical research purposes or for statistical
            purposes in accordance with Art. 89 Para. 1 of the DSGVO, unless
            such processing is necessary for the fulfilment of a task in the
            public interest.
          </p>
          <p>
            In order to exercise the right of objection, the person concerned
            may contact our data protection officer directly. The data subject
            is also free to exercise his or her right of objection in connection
            with the use of information society services, notwithstanding
            Directive 2002/58/EC, by means of automated procedures using
            technical specifications.
          </p>
          <p>
            <strong>
              (h) Automated case-by-case decisions, including profiling
            </strong>
            <br />
            Every person concerned by the processing of personal data has the
            right, granted by the European legislator, not to be subject to a
            decision based solely on automated processing, including profiling,
            which produces legal effects concerning him or her or significantly
            affects him or her in a similar way, provided that the decision (1)
            is not necessary for the conclusion or performance of a contract
            between the data subject and the controller, or (2) is authorised by
            Union or national legislation to which the controller is subject and
            such legislation provides for adequate measures to safeguard the
            rights and freedoms and legitimate interests of the data subject, or
            (3) is made with the explicit consent of the data subject.
          </p>
          <p>
            If the decision (1) is necessary for the conclusion or performance
            of a contract between the person concerned and the data controller
            or (2) is made with the express consent of the person concerned,
            Moneta shall take reasonable measures to safeguard the rights and
            freedoms as well as the legitimate interests of the person
            concerned, which shall include at least the right to obtain the
            intervention of a person from the data controller, to present its
            own position and to contest the decision.
          </p>
          <p>
            If the data subject wishes to exercise rights relating to automated
            decisions, he or she may contact our Data Protection Officer at any
            time.
          </p>
          <p>
            <strong>(i) Right to revoke a data protection consent</strong>
            <br />
            Every person affected by the processing of personal data has the
            right granted by the Swiss legislator or the European Directive and
            Regulation Giver to revoke his or her consent to the processing of
            personal data at any time.
          </p>
          <p>
            If the data subject wishes to exercise his or her right to revoke
            consent, he or she may contact our data protection officer at any
            time.
          </p>
          <h1>
            <strong>24. Legal basis of the processing</strong>
          </h1>
          <p>
            Art. 6 I lit. a DSGVO serves our company as a legal basis for
            processing operations for which we obtain consent for a specific
            processing purpose. If the processing of personal data is necessary
            for the performance of a contract to which the data subject is a
            party, as is the case, for example, with processing operations
            necessary for the supply of goods or provision of other services or
            consideration, the processing is based on Art. 6 I lit. b DSGVO. The
            same applies to such processing operations which are necessary for
            the implementation of pre-contractual measures, for example in cases
            of enquiries about our products or services. If our company is
            subject to a legal obligation which makes the processing of personal
            data necessary, for example to fulfil tax obligations, the
            processing is based on Art. 6 I lit. c DSGVO.
          </p>
          <p>
            In rare cases, the processing of personal data may be necessary to
            protect the vital interests of the data subject or another natural
            person. This would be the case, for example, if a visitor to our
            company were to be injured and his or her name, age, health
            insurance details or other vital information had to be forwarded to
            a doctor, hospital or other third party. In this case the processing
            would be based on Art. 6 I lit. d DSGVO. Finally, processing
            operations could be based on Art. 6 I lit. f DSGVO. Processing
            operations which are not covered by any of the above legal bases are
            based on this legal basis if the processing is necessary to
            safeguard a legitimate interest of our company or of a third party,
            unless the interests, fundamental rights and freedoms of the data
            subject prevail.
          </p>
          <p>
            We are permitted to carry out such processing operations in
            particular because they have been specifically mentioned by the
            European legislator. In this respect, it took the view that a
            legitimate interest could be assumed if the data subject is a
            customer of the controller (Recital 47 Sentence 2 DSGVO).
          </p>
          <h1>
            <strong>
              25. Legitimate Interests in the Processing Pursued by the
              Controller or a Third Party
            </strong>
          </h1>
          <p>
            If the processing of personal data is based on Article 6 I lit. f
            DSGVO, our legitimate interest is to conduct our business activities
            for the benefit of the well-being of all our employees and our
            shareholders.
          </p>
          <h1>
            <strong>26. Duration for which personal data are stored</strong>
          </h1>
          <p>
            The criterion for the duration of storage of personal data is the
            respective legal retention period. After expiry of the period, the
            corresponding data is routinely deleted if it is no longer required
            for contract fulfilment or contract initiation.
          </p>
          <h1>
            <strong>
              27. Legal or contractual provisions on the provision of personal
              data; necessity for the conclusion of a contract; obligation of
              the data subject to provide the personal data; possible
              consequences of not providing the data
            </strong>
          </h1>
          <p>
            We would like to inform you that the provision of personal data is
            partly required by law (e.g. tax regulations) or can also result
            from contractual regulations (e.g. information on the contractual
            partner). Sometimes it may be necessary for a contract to be
            concluded that a data subject provides us with personal data, which
            must subsequently be processed by us. For example, the data subject
            is obliged to provide us with personal data if our company concludes
            a contract with him/her. Failure to provide the personal data would
            mean that the contract with the data subject could not be concluded.
            Before the person concerned makes personal data available, the
            person concerned must contact one of our employees. Our employee
            will inform the data subject on a case-by-case basis whether the
            provision of personal data is required by law or contract or
            necessary for the conclusion of the contract, whether there is an
            obligation to provide the personal data and what the consequences
            would be if the personal data were not provided.
          </p>
          <h1>
            <strong>28. Existence of automated decision making</strong>
          </h1>
          <p>
            As a responsible company, we avoid automatic decision making or
            profiling.
          </p>
          <h1>
            <strong>29. Adaptation of the data protection declaration</strong>
          </h1>
          <p>
            We may amend our privacy policy at any time by publishing it on this
            website.
          </p>
          <p>
            <em>Moneta, September 2021</em>
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
